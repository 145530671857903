/* ServiceButton.css */
.service-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    background-color: rgb(43, 43, 43);
    color: white;
    padding: 10px;
  }

  .service-button:hover {
    background-color: green;
  }

  .service-button img {
    height: 50px;
  }